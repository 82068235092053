<template>
  <div>
    <b-card class="shadow">
      <form id="registration-form" @submit.prevent="handleSubmit">
        <b-row>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="firstName"
                ><small>First Name</small>
              </label>
              <b-form-input
                v-model="userCopy.firstName"
                required
                id="firstName"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="lasttName"
                ><small>Last Name</small>
              </label>
              <b-form-input
                required
                v-model="userCopy.lastName"
                id="lasttName"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="mi"><small>MI</small> </label>
              <b-form-input
                required
                v-model="userCopy.mi"
                id="mi"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group>
              <label class="text-muted" for="dateOfBirth"
                ><small>Date of Birth</small>
              </label>
              <b-form-input
                required
                v-model="userCopy.dateOfBirth"
                type="date"
                id="dateOfBirth"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <label class="text-muted" for="address"
            ><small>Complete Address</small>
          </label>
          <b-form-input
            required
            v-model="userCopy.address"
            id="address"
          ></b-form-input>
        </b-form-group>
        <b-row>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="contactNo"
                ><small>Contact No.</small>
              </label>
              <b-form-input
                required
                v-model="userCopy.contactNo"
                type="tel"
                id="contactNo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="email"
                ><small>Email</small>
              </label>
              <b-form-input
                v-model="userCopy.email"
                required
                type="email"
                id="email"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="password"
                ><small>Password</small>
              </label>
              <b-form-input
                v-model="userCopy.password"
                type="password"
                required
                id="password"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group>
              <label class="text-muted" for="confirmPassword"
                ><small>Confirm Password</small>
              </label>
              <b-form-input
                required
                v-model="confirmPassword"
                type="password"
                id="confirmPassword"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="error">
          <b-col>
            <p class="mb-0 text-danger">
              {{ error }}
            </p>
          </b-col>
        </b-row>
        <hr class="py-1" />
        <b-button type="submit" variant="primary" block size="lg">
          Register
        </b-button>
      </form>
      <template #footer>
        <div class="text-center w-100">
          <small>
            <router-link :to="{ name: 'AuthLogin' }">
              Have an account? Go to login
            </router-link>
          </small>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
  export default {
    name: 'RegisterForm',

    props: {
      user: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        userCopy: { ...this.user },
        confirmPassword: '',
        error: ''
      }
    },

    methods: {
      async handleSubmit () {
        this.$emit('submitting', this.userCopy)
      }
    }
  }
</script>

<style>
</style>
