<template>
  <b-container>
    <b-row class="justify-content-center align-items-center py-4">
      <b-col lg="6">
        <div class="w-100 text-center mb-4">
          <h4 class="mb-0 font-weight-bold text-primary">Create an Account</h4>
        </div>
        <register-form :user="user" @submitting="register"></register-form>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import UserService from '@/services/UserService'
  import RegisterForm from '@/components/RegisterForm'

  export default {
    name: 'AuthRegister',

    props: {
      role: {
        type: String,
        required: false,
        default: 'taxpayer'
      }
    },

    components: {
      RegisterForm
    },

    data () {
      return {
        user: {}
      }
    },

    methods: {
      async register (data) {
        this.$refs.spinner.show()

        try {
          // Attach user role
          data.role = this.role
          await UserService.register(data)
          this.$router.push({ name: 'AuthRegisterSuccess' })
        } catch (error) {
          console.log(error.response.data)
          this.$refs.toast.error(error.response.data.message)
        }

        this.$refs.spinner.hide()
      }
    }
  }
</script>

<style>
</style>
